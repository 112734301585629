// 订阅
import request from '../utils/request';

// 获取订阅计划列表
export async function getPlanList() {
    try {
        return await request({
            url: `/subscriptions/getSubscriptions`,
            method: 'post'
        });
    } catch (error) {
        return {};
    }
}

// 获取价格
export async function getPrices(data) {
    try {
        return await request({
            url: `/subscriptions/getPrice`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

//获取planId
export async function getPlanId(data) {
    try {
        return await request({
            url: `/order/payPalPlan`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 保存subscriptionID
export async function saveSubscriptionId(data) {
    try {
        return await request({
            url: `/order/setSubscriptions`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取订阅列表
export async function getSubscriptionList() {
    try {
        return await request({
            url: `/subscriptions`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

//取消订阅
export async function cancelSubscriptions(id) {
    try {
        return await request({
            url: `/order/cancelSubscriptions/${id}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}
// 获取最低价格
export async function getMinimumPrice() {
    try {
        return await request({
            url: `/subscriptions/getMinimumPrice`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取订阅详情
export async function getSubscriptionDetail(id) {
    try {
        return await request({
            url: `/subscriptions/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 更新订阅

export async function updateSubscription(data) {
    try {
        return await request({
            url: `/order/updateSubscriptions`,
            method: 'put',
            data
        });
    } catch (error) {
        return {};
    }
}

// 检查计划
export async function checkPlan(data) {
    try {
        return await request({
            url: `/subscriptions/check`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 创建订单
export async function createOrder(data) {
    try {
        return await request({
            url: `/order/subscriptionsCategoryPayment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// paypal检查回调
export async function paypalPaymentCallback(orderId) {
    try {
        return await request({
            url: `/payment/callback/paypal?orderId=${orderId}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}