<template></template>

<script>
import { getClass, checkFirstBuy } from "../service";
import { setStorage, setStorageForArray } from "./common";

export default {
  name: "courseMix",
  data() {
    return {
      courseId: "",
      classInfo: {},
      comboData: [],
      filterData: [],
      selectCombo: null,
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课目录", index: "1", tag: "course_content" },
        // { name: "班课模式", index: "2", tag: "course_mode" },
        { name: "用户评价", index: "2", tag: "course_comment" },
        { name: "售前咨询", index: "3", tag: "course_question" },
      ],
    };
  },
  mounted() {
    const path = this.$route.path;
    if (path && path.indexOf("/course/") > -1) {
      this.courseId = path.replace("/course/", "");
      this.getClassPriceInfo();
    } else if (path == "/subscribe") {
      this.courseId = 2;
      this.getClassPriceInfo();
    } else {
      this.$message.error("课程数据错误");
    }
  },
  methods: {
    TabActiveIndexChange(index) {
      this.TabActiveIndex = index;
      console.log("------", this.TabActiveIndex);
    },

    getClassPriceInfo() {
      getClass(this.courseId)
        .then((res) => {
          if (!res.result.code) {
            this.classInfo = res.result;

            

            if (this.$route.path == "/course/2") {
              this.comboData = res.result.courseCombosList;
              this.comboData.forEach((item) => {
                item.name = item.name.split("（")[0];
              });
              this.filterData = this.unique(this.comboData);
              this.finalCourse = this.filterData[0];
              this.recommendClasses = res.result.recommendClasses; //相关课程推荐byzuhuimin
            } else {
              this.comboData = res.result.courseCombosList;
              this.recommendClasses = res.result.recommendClasses; //相关课程推荐byzuhuimin
            }
			
          }
        })
        .catch((err) => {
          console.log("courseInfo", err);
        });
    },
    buyCombo() {
      console.log(123);
      if (!this.comboData || this.comboData.length === 0) {
        this.$message.error("套餐数据错误");
        return;
      }
      console.log(this.selectCombo);
      try {
        if (!this.selectCombo) {
          this.selectCombo = this.comboData[0];
        }
      } catch (e) {
        this.$message.error("套餐数据错误");
        return;
      }

      let cart = {
        imgUrl: this.classInfo.coverUrl,
        objectName: this.selectCombo.comboName,
        objectId: this.selectCombo.id,
        classId: this.selectCombo.courseClassId,
        id: "",
        price: this.selectCombo.usdprice || 0,
        realPrice: this.selectCombo.usdprice,
        comboId: this.selectCombo.id,
      };
      setStorage("realPrice", this.selectCombo.usdprice || 0);
      setStorage("totalprice", this.selectCombo.usdprice || 0);
      if (this.selectCombo.firstBuyPrice) {
        //查询是否为首单
        checkFirstBuy(this.selectCombo.id).then((res) => {
          if (res.success && res.success.result) {
            cart.price = this.selectCombo.firstBuyPrice;
          }
          setStorage("totalprice", this.selectCombo.firstBuyPrice);
          setStorageForArray("orderSelect", [cart]);

          this.$router.push("/confirm?type=1");
        });
      } else {
        setStorageForArray("orderSelect", [cart]);
        this.$router.push("/confirm?type=1");
      }
    },
    unique(arr) {
      let newArr = [arr[0]];
      for (let i = 1; i < arr.length; i++) {
        let repeat = false;
        for (let j = 0; j < newArr.length; j++) {
          if (arr[i].name === newArr[j].name) {
            repeat = true;
            break;
          } else {
          }
        }
        if (!repeat) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
  },
};
</script>

<style scoped>
</style>
