<template>
  <!-- 订阅主页 -->
  <div id="subscribe">
    <!-- <div class="banner">
      <img src="@/assets/img/subscribe/subscribeBanner.png" alt="" />
      <span class="banner-title">LeetCode千题视频讲解会员订阅</span>
    </div> -->
    <div class="recommend">
      <div class="container">
        <div class="title">为你推荐 <span>RECOMMEND</span></div>
<!--        <div class="subtitle">LeetCode千题视频讲解会员，找到更好的工作</div>-->
        <div class="plans">
          <!--          <div class="plan">-->
          <!--            <img src="@/assets/img/subscribe/hot.png" class="tip" alt="" />-->
          <!--            <div class="duration">每月</div>-->
          <!--            <div class="duration-translate">EVERY MONTH</div>-->
          <!--            <div class="innerplan">-->
          <!--              <div class="planName">LeetCode 1-300题</div>-->
          <!--              <div class="planPrice">-->
          <!--                <span>$</span-->
          <!--                ><span class="priceCount">{{ plans[0].price }}</span-->
          <!--                ><span>/ 月</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="innerplan">-->
          <!--              <div class="planName">LeetCode 1-600题</div>-->
          <!--              <div class="planPrice">-->
          <!--                <span>$</span-->
          <!--                ><span class="priceCount">{{ plans[1].price }}</span-->
          <!--                ><span>/ 月</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="innerplan">-->
          <!--              <div class="planName">LeetCode 1-1000题</div>-->
          <!--              <div class="planPrice">-->
          <!--                <span>$</span-->
          <!--                ><span class="priceCount">{{ plans[2].price }}</span-->
          <!--                ><span>/ 月</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <el-button class="buy" @click="toSubscribe">查看详情</el-button>-->
          <!--          </div>-->
          <div class="plan">
            <!--            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />-->
            <div class="duration">2个月</div>
            <div class="duration-translate">TWO MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-300题</div>
              <div class="description">无分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
              <!--              <div class="description">微信群答疑</div>-->
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[0] && comboData[0].usdprice
              }}</span>
            </div>
            <!--            <div class="originPrice">-->
            <!--              原价：${{ comboData[0] && comboData[0].usdprice }}-->
            <!--            </div>-->
            <el-button
              class="buy"
              style="margin-top: 16px"
              @click="buyCourse(comboData[0])"
              >立即购买</el-button
            >
          </div>
          <div class="plan">
            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />
            <div class="duration">6个月</div>
            <div class="duration-translate">SIX MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-1000题</div>
              <div class="description">LeetCode 600题分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
              <!--              <div class="description">微信群答疑</div>-->
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[1] && comboData[1].usdprice
              }}</span>
            </div>
            <!--            <div class="originPrice">-->
            <!--              原价：${{ comboData[1] && comboData[1].usdprice }}-->
            <!--            </div>-->
            <el-button
              class="buy"
              style="margin-top: 16px"
              @click="buyCourse(comboData[1])"
              >立即购买</el-button
            >
          </div>
          <div class="plan">
            <!--            <img src="@/assets/img/subscribe/sale.png" class="tip" alt="" />-->
            <div class="duration">9个月</div>
            <div class="duration-translate">NINE MONTHS</div>
            <div class="descriptions">
              <div class="description">LeetCode 1-1000题</div>
              <div class="description">LeetCode 600题分类顺序表</div>
              <div class="description">三种语言代码</div>
              <div class="description">Java/C++/Python</div>
              <!--              <div class="description">微信群答疑</div>-->
            </div>
            <div class="planPrice">
              <span>$</span
              ><span class="priceCount">{{
                comboData[2] && comboData[2].usdprice
              }}</span>
            </div>
            <!--            <div class="originPrice">-->
            <!--              原价：${{ comboData[2] && comboData[2].usdprice }}-->
            <!--            </div>-->
            <el-button
              class="buy"
              style="margin-top: 16px"
              @click="buyCourse(comboData[2])"
              >立即购买</el-button
            >
          </div>
        </div>
      </div>
    </div>
<!--    <div class="other">-->
<!--      <div class="container">-->
<!--        <div class="title">其他服务供你选择 <span>OTHER SERVICE</span></div>-->
<!--        <div class="choices">-->
<!--          <el-popover-->
<!--            placement="top"-->
<!--            width="360"-->
<!--            trigger="hover"-->
<!--            :close-delay="0"-->
<!--          >-->
<!--            <div class="choice-container" style="padding: 20px">-->
<!--              <img src="@/assets/img/subscribe/hoverPic1.png" alt="" />-->
<!--              <div class="hoverText">-->
<!--                LeetCode 600题分类顺序表详细-->
<!--                <br />-->
<!--                网站上仅为前400题精简版本-->
<!--              </div>-->
<!--            </div>-->
<!--            <div slot="reference" class="choice">-->
<!--              <img class="icon" src="@/assets/img/subscribe/icon1.png" alt="" />-->
<!--              <div class="iconDes">LeetCode 600题分类顺序表</div>-->
<!--            </div>-->
<!--          </el-popover>-->

<!--          <el-popover-->
<!--            placement="top"-->
<!--            width="590"-->
<!--            trigger="hover"-->
<!--            :close-delay="0"-->
<!--          >-->
<!--            <div class="choice-container" style="padding: 20px">-->
<!--              <img src="@/assets/img/subscribe/hoverPic4.png" alt="" />-->
<!--              <div class="hoverText">-->
<!--                做题前思路一句话点睛提示，重要性 &amp; 高频性分析-->
<!--              </div>-->
<!--            </div>-->
<!--            <div slot="reference" class="choice">-->
<!--              <img class="icon" src="@/assets/img/subscribe/icon4.png" alt="" />-->
<!--              <div class="iconDes">思路点评 &amp; 高频分析</div>-->
<!--            </div>-->
<!--          </el-popover>-->

<!--          <el-popover-->
<!--            placement="top"-->
<!--            width="360"-->
<!--            trigger="hover"-->
<!--            :close-delay="0"-->
<!--            ><div class="choice-container" style="padding: 20px">-->
<!--              <img src="@/assets/img/subscribe/hoverPic2.png" alt="" />-->
<!--              <div class="hoverText">解锁视频播放页面右侧代码区</div>-->
<!--            </div>-->

<!--            <div slot="reference" class="choice">-->
<!--              <img class="icon" src="@/assets/img/subscribe/icon2.png" alt="" />-->
<!--              <div class="iconDes">三种语言代码(Java/C++/Python)</div>-->
<!--            </div>-->
<!--          </el-popover>-->
<!--          <div class="choice">-->
<!--            <img class="icon" src="@/assets/img/subscribe/icon3.png" alt="" />-->
<!--            <div class="iconDes">助教微信群答疑</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import { getPlanList, getPrices } from "@/service/subscribe";
import courseMix from "../../utils/courseMix";
export default {
  name: "subscribe",
  data() {
    return {
      plans: [],
    };
  },
  methods: {
    toSubscribe() {
      this.$router.push("/subscribe/choosePlan");
    },
    buyCourse(item) {
      this.selectCombo = item;
      this.buyCombo();
    },
    getPlanList() {
      getPlanList().then((res) => {
        if (res.success) {
          this.plans = res.result;
        }
      });
    },
  },
  mixins: [courseMix],
  mounted() {
    this.getPlanList();
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  position: relative;
  img {
    vertical-align: top;
    width: 100%;
  }
  .banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 26px;
  }
}
.recommend {
  padding: 50px 0;
  width: 100%;
  background: #f8f8f8;
  .container {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 28px;
      margin-bottom: 20px;
      span {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 18px;
      }
    }
    .subtitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 18px;
    }
    .plans {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .plan {
        width: 320px;
        background: #ffffff;
        box-shadow: 0px 0px 39px 0px rgba(120, 120, 128, 0.16);
        border-radius: 8px;
        text-align: center;
        padding: 68px 0;
        position: relative;
        .tip {
          position: absolute;
          left: 0;
          top: 0;
          width: 126px;
        }
        .duration {
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #999999;
          line-height: 42px;
        }
        .duration-translate {
          padding-top: 14px;
          padding-bottom: 36px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(120, 120, 128, 0.36);
          line-height: 21px;
        }
        .innerplan {
          margin-bottom: 24px;
        }
        .planName {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 21px;
          margin-bottom: 10px;
        }
        .planPrice {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          line-height: 21px;
          color: #e59f3c;
          .priceCount {
            font-size: 28px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;

            line-height: 42px;
            position: relative;
          }
        }
        .originPrice {
          text-decoration: line-through;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 21px;
        }
        .descriptions {
          margin-bottom: 12px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 40px;
        }
        .buy {
          background: #f3bb6c;
          border-radius: 4px;
          color: #fff;
          padding: 10px 72px;
        }
      }
    }
  }
}
.other {
  padding-top: 40px;
  padding-bottom: 120px;
  background: #f2f2f2;
  .container {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 28px;
      margin-bottom: 20px;
      span {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 18px;
      }
    }
    .choices {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .choice {
        width: 240px;
        height: 160px;
        background: #ffffff;
        border-radius: 8px;
        text-align: center;
        .icon {
          width: 40px;
          height: 40px;
          margin-top: 42px;
          margin-bottom: 20px;
        }
        .iconDes {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #e59f3c;
          line-height: 21px;
        }
      }
    }
  }
}
.choice-container {
  padding: 20px;
  text-align: center;
  ::v-deep img {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}
.hoverText {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 16px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>